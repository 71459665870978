import request from '@/utils/request'

export function getInventoryList(params) { // 盘点列表
    return request({
        url: '/api/inventory_list/',
        method: 'get',
        params
    })
}
export function getInventoryDtl(params) {  // 盘点详情
    return request({
        url: '/api/inventory_detail/',
        method: 'get',
        params
    })
}
export function saveInventory(data) {  // 盘点信息提交
    return request({
        url: '/api/add_inventory/',
        method: 'post',
        data
    })
}
export function updateInventory(data) {  // 盘点信息提交
    return request({
        url: '/api/add_inventory/',
        method: 'put',
        data
    })
}
export function getCheckAssetList(params) {  // 盘点详情资产列表
    return request({
        url: '/api/inventory_asset_list/',
        method: 'get',
        params
    })
}
export function checkConfirm(data) {  // 盘点信息确认提交
    return request({
        url: '/api/submit_asset_inventory/',
        method: 'post',
        data
    })
}

export function deleteCheck(data) {  // 盘点信息确认提交
    return request({
        url: '/api/delete_inventory/',
        method: 'post',
        data
    })
}
export function exportCheck(params) {  // 盘点详情资产列表
    return request({
        url: '/api/export_asset_inventory/',
        method: 'get',
        params
    })
}
export function tempInventory(params) {  // 获取盘点暂存信息
    return request({
        url: '/api/add_inventory/',
        method: 'get',
        params
    })
}










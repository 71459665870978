import {getCheckAssetList} from "@/api/assetCheck";
import {deepCopy} from "ali-oss/lib/common/utils/deepCopy";

export default {
    data(){
        return{
            statusList:[
                {label: '待盘点',value: '1500'},
                {label: '盘点中',value: '1501'},
                {label: '已完成',value: '1502'},
            ],
        }
    },
    created() {

    },
    methods:{
        handleStatus(val){
            let statusName, className;
            switch (val) {
                case 1500:
                    statusName = "待盘点";
                    className = "wait";
                    break;
                case 1501:
                    statusName = "盘点中";
                    className = "checking";
                    break;
                case 1502:
                    statusName = "已完成";
                    className = "completed";
                    break;
                default:
                    statusName = "Unknown";
                    className = "unknown-status";
            }
            return {
                statusName: statusName,
                className: className
            };
        },
        getTableList(){
            this.loading = true
            let params = {
                size: this.size,
                page: this.page,
                note: this.$route.query.note_id,
            }
            getCheckAssetList(params).then(res => {
                this.loading = false
                this.total = res.data.count
                this.tableDataTemp = Object.assign(res.data.data)
                this.tableData = Object.assign(res.data.data)
            })
        },
        handlePosition(val){
            let positionName
            switch (val) {
                case 1530:
                    positionName = "医院";
                    break;
                case 1531:
                    positionName = "经销商";
                    break;
                case 1532:
                    positionName = "奥普";
                    break;
                default:
                    positionName = "Unknown";
            }
            return {
                positionName: positionName,
            };
        },
    }
}